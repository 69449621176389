/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const ProzaLibre_400Regular = require('./ProzaLibre_400Regular.ttf');
export const ProzaLibre_400Regular_Italic = require('./ProzaLibre_400Regular_Italic.ttf');
export const ProzaLibre_500Medium = require('./ProzaLibre_500Medium.ttf');
export const ProzaLibre_500Medium_Italic = require('./ProzaLibre_500Medium_Italic.ttf');
export const ProzaLibre_600SemiBold = require('./ProzaLibre_600SemiBold.ttf');
export const ProzaLibre_600SemiBold_Italic = require('./ProzaLibre_600SemiBold_Italic.ttf');
export const ProzaLibre_700Bold = require('./ProzaLibre_700Bold.ttf');
export const ProzaLibre_700Bold_Italic = require('./ProzaLibre_700Bold_Italic.ttf');
export const ProzaLibre_800ExtraBold = require('./ProzaLibre_800ExtraBold.ttf');
export const ProzaLibre_800ExtraBold_Italic = require('./ProzaLibre_800ExtraBold_Italic.ttf');
